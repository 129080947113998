<template>
  <div class="animated fadeIn">
    <div class="div-button-novo-jeito">
      <button class="button-novo-jeito" v-on:click="abrirModal()"><img src="../assets/img/Botão.png" alt=""></button>
    </div>
    <div id="Dashboard">
      <span class="saudacao-cliente"> Olá {{ formatarNome(this.$store.state.login.user.first_name) }}, </span>  seja bem-vindo
    </div>
    <div id="cards" v-if="showCards">
      <div id="card-group">
        <div id="card-cadastro">
        <div class="card-top">
          <img src="../assets/img/iconecadastro.png" alt="Ícone do cadastro">
          <h4> Cadastro </h4>
        </div>
        <div class="card-bottom">
          <hr>
          <div>
            <a :href="getWhatsapp('cadastro')"  target="_blank" id="Link do whatsapp do cadastro">
              <img src="../assets/img/iconewhats.png" alt="Ícone do telefone whatsapp do cadastro">
            </a>
            <span> Cadastre e gerencie clientes na sua base. </span>
          </div>
        </div>
        </div>
        <div id="card-financeiro">
          <div class="card-top">
            <img src="../assets/img/iconecadastro.png" alt="Ícone do financeiro">
              <h4> Financeiro </h4>              
          </div>
          <div class="card-bottom">
            <hr>
            <div>
              <a :href="getWhatsapp('financeiro')"  target="_blank" id="Link do whatsapp do financeiro">
                <img src="../assets/img/iconewhats.png" alt="Ícone do telefone whatsapp do financeiro">
              </a>
              <span> Recebimento de prêmios e emissão de notas fiscais. <br/> Solicitação de boletos de clientes. </span>
            </div>
          </div>
        </div>
        <div id="card-suporte">
          <div class="card-top">
            <img src="../assets/img/iconecadastro.png" alt="Ícone do suporte">
              <h4> Suporte </h4>
          </div>
          <div class="card-bottom">
            <hr>
            <div>
              <a :href="getWhatsapp('suporte')" target="_blank" id="Link do whatsapp do suporte">
                <img src="../assets/img/iconewhats.png" alt="Ícone do telefone whatsapp do suporte">
              </a>
              <span> Tire suas dúvidas através do nosso suporte técnico. </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-mensagem" size="xl"
    :hide-header="true"
    :hide-footer="true">
    <template>
        <div style="width: 100%"  v-if="mensagemVisualizacao">
          <b-form-checkbox
            id="checkbox-1"
            class="estiloCheckbock"
            v-model="check"
            name="checkbox-1"
            unchecked-value="not_accepted"
          >
            <span>Não visualizar novamente</span>
          </b-form-checkbox>
        </div>
      </template>
      <button size="sm" class="buttonClose" @click="fecharModal()"></button>
      <img src="../assets/img/Mensagem.jpg" alt="" class="imagem">
    </b-modal>
  </div>
</template>

<style>
.saudacao-cliente {
  color:  #5804ca;
  font-weight: bold;
}

div#Dashboard {
  font-size: 15px;
}
.displayNone{
  display: none;
}
.marginTopModal{
  margin-top: 10%;
}

.imagem{
    width: 100%;
}

.padding0{
  padding: 0 !important;
}

.marginModal{
    margin-top: 13%;
    margin-left: 2%;
}

.estiloCheckbock{
  display: flex;
  padding: 0.3%;
  justify-content: flex-end;
}

.buttonFechar{
  padding: 1%;
  margin-top: 1%;
  margin-right: 1%;
  margin-bottom: 1%;
}
.button-novo-jeito{
  background: none;
  border: none;
  margin-top: -6em;
}
.div-button-novo-jeito{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.estilo-modal-content{
    margin-top: 15% !important;
    width: 120% !important;
}

.buttonClose{
    position: absolute;
    width: 7.5em;
    height: 2em;
    margin-left: 91%;
    margin-top: 3%;
}

.buttonClose{
  background: transparent;
  color: transparent;
  border: none;
}

button:focus {
  outline: none !important; 
}

@media (max-width: 1600px){
  .estilo-modal-content{
    margin-top: 17% !important;
    width: 110% !important;
    margin-left: 4% !important;
  }
}

@media (max-width: 1450px){
  .estilo-modal-content{
    margin-top: 19% !important;
    width: 100% !important;
    margin-left: 10% !important;
  }
}

@media (max-width: 1300px){
  .estilo-modal-content{
    margin-top: 19% !important;
    width: 90% !important;
    margin-left: 15% !important;
  }
}
</style>

<script>
import Revenda from "../services/revenda";

export default {
  name: "inicio",
  data()
  {
    return{
      check: '',
      mensagemVisualizacao: true,
      showCards: false,

    }
  },
  mounted()
  {
    this.abrirMensagem();
  },
  methods: 
  {
    abrirMensagem()
    {
      Revenda.getStatusPopupUsuario(this.$store.state.login.user.id)
        .then(response => {
            if(response.data.bo_popup == "S")
            {
              this.openModal();
            }
            if(document.documentMode)
            {
              alert("Aplicação não compativel com o navegador, acesse com um destes navegadores (Chrome, Firefox, Safari, Mozilla, Opera)")
            }
        })
        .catch(e => {
          this.$helper.showErrorResponse(e);
        });
    },
    openModal()
    {
      this.$bvModal.show('modal-mensagem');
      let clock = setTimeout(() => {
        let modalContent = document.getElementById('modal-mensagem___BV_modal_content_');
        modalContent.classList.add('estilo-modal-content')
        document.getElementById('modal-mensagem___BV_modal_body_').style.padding='0';
      }, 20)
    },
    fecharModal()
    {
      if(this.check && this.mensagemVisualizacao)
      {
        Revenda.atualizarStatusMensagem(this.$store.state.login.user.id)
        .then()
        .catch(e => {
          this.$helper.showErrorResponse(e);
        });
      }
      this.$bvModal.hide('modal-mensagem');
    },
    abrirModal()
    {
      this.mensagemVisualizacao = false;
      this.openModal();
    },
    formatarNome(nome)
    {
      return nome.toLowerCase().replace(/(?:^|\s)\S/g, function(capitalize) { return capitalize.toUpperCase(); });
    }
  }
};
</script>
